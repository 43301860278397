import { Ref } from 'vue';
import {
  NavigationGuardNext,
  RouteLocationNamedRaw,
  RouteLocationNormalized,
} from 'vue-router';
import { $t } from '@/i18n';
import { BooleanNumber, StorageSortDirection } from '@/features/core/storage';
import { DeliveryUnit } from '@/features/delivery-unit';
import { Product } from '@/features/products/entities';
import { TemperatureClassList } from '@/features/products';
import { Order } from '../entities';
import { OrderCard } from '../components';
import {
  HandoverCompletedEvent,
  HandoverStartedEvent,
  PickingCompletedEvent,
  PickingContinuedEvent,
  PickingStartedEvent,
} from '../events';

export interface OrderBag {
  quantity: number;
  sku: string;
  name: string;
}

export interface OrderCheckIn {
  firstName?: string;
  lastName?: string;
  location?: string;
  timestamp?: string;
  isCheckedIn: BooleanNumber;
  isAcknowledged?: boolean;
}

export interface StartPickingSwitchers {
  isProcessing: Ref<boolean>;
  isUserTokenRefreshing: Ref<boolean>;
  isOrderAlreadyStarted: Ref<boolean>;
  isPickingCancelledOrCompleted: Ref<boolean>;
}

export interface OrderCheckInResponse extends Omit<OrderCheckIn, 'timestamp'> {
  timestamp: string;
}

export interface Customer {
  firstName: string;
  lastName: string;
  phone?: string;
}

export interface OrderWeight {
  relatedBarcode: string;
  weight: number;
}

export interface OrderItem {
  id: string;
  quantity: number;
  quantityOriginal: number;
  amount: number;
  amountOriginal: number;
  unit: string;
  status: OrderItemStatus;
  product: Product;
  picked?: boolean;
  // `isRejected` exists mainly to signalize when a `staged` item is rejected
  isRejected?: boolean;
  weights?: OrderWeight[];
  weight?: number[];
  isReplaceable: boolean;
  originalId?: string | null;
  replacements?: OrderItem[];
  rejectionReason?: string;
  isBarcodeValidated?: boolean;
}

export enum ItemUnit {
  Gram = 'g',
  Kilogram = 'kg',
  Pound = 'lb',
}

export interface OrderItemCount {
  total: number;
  chiller: number;
  fresh: number;
  ambient: number;
  freezer: number;

  [key: string]: number;
}

export interface OrderItemRaw extends Omit<OrderItem, 'product'> {
  product: string;
  scannedBarcodes: string[];
}

export interface OrderRaw
  extends Omit<Order, 'items' | 'customer' | 'orderMetadata'> {
  items: OrderItemRaw[];
  firstName: string;
  lastName: string;
  orderMetadata: string;
}

export interface OrderAction {
  title: string;
  action?: string;
  targetStatus: OrderActionStatus;
}

export interface UseCancelOrder {
  cancelOrder: (order: Order) => Promise<void>;
  cancellationInProcess: Ref<boolean>;
}

export interface UseOrderList {
  search: Ref<string>;
  searchIsActive: Ref<boolean>;
  activatedSearch: (active: boolean) => void;
  redirectGuard: (next: NavigationGuardNext) => void;
  handleRoute: (
    route: RouteLocationNormalized,
    cardsRefs: Ref<{ [key: string]: typeof OrderCard }>,
  ) => Promise<string | null>;
}

export enum OrderActionStatus {
  picking = 'picking_started',
  picking_completed = 'picking_completed',
  cancellation_started = 'cancellation_started',
  handover = 'pickup_started',
  handover_completed = 'pickup_completed',
  paused = 'paused',
}

export enum OrderLocalStatus {
  PickingReady = 'picking-ready',
  PickingInProgress = 'picking-in-progress',
  PickingCompleted = 'picking-completed',
  PickingCancelled = 'picking-cancelled',
  HandoverReady = 'handover-ready',
  HandoverInProgress = 'handover-in-progress',
  HandoverCompleted = 'handover-completed',
  HandoverCancelled = 'handover-cancelled',
  Paused = 'paused',
}

export enum OrderPopupStatus {
  info_popup = 'info',
  transition_popup = 'transition_popup',
}

export enum OrderItemStatus {
  picked = 'picked',
  not_picked = 'not_picked',
  cancelled = 'cancelled',
  staged = 'staged',
  rejected = 'rejected',
  acknowledged = 'acknowledged',
  out_for_delivery = 'out for delivery',
}

export enum OrderEventNames {
  picking_started = 'picking_started',
  picking_completed = 'picking_finished',
  staging_started = 'staging_started',
  staging_completed = 'staging_end',
  pickup_started = 'handover_started',
  pickup_ready = 'handover_ready',
  bags_picked = 'bags_picked_handover_started',
  pickup_completed = 'handover_finished',
  pickup_canceled = 'handover_canceled',
  pausing_started = 'picking_pause_start',
  pausing_completed = 'picking_pause_end',
  picking_resumed = 'picking_restored',
  notification_handed_os = 'notification_handed_os',
  item_handling_completed = 'item_handling_completed',
  checkin_acknowledged = 'checkin_acknowledged',
}

export interface OptionsEvents {
  allowMultipleTracking?: boolean;
  skipLoggingOfDuplicateTracking?: boolean;
  skipSaving?: boolean;
}

export enum FallbackType {
  'noPickingOrders',
  'noFilteredPickingOrders',
  'noHandoverOrders',
}

export interface OrderResponse {
  id: string;
  type: string;
  attributes: {
    orderReference: string;
    status: string;
    pickupCode: string;
    cartNote: string;
    checkin: OrderCheckInResponse | null;
    startTime: string;
    endTime: string;
    items: OrderItemResponse[];
    actions: OrderAction[];
    firstName: string;
    lastName: string;
    creationTime?: string;
    events?: EventList[];
    deliveryUnits?: DeliveryUnit[];
  };
  links?: {
    self: string;
  };
}

export interface OrderParams {
  id?: string;
  status?: string;
  startTime?: Date;
  endTime?: Date;
  orderReference?: string;
  pickupCode?: string;
  cartNote?: string;
  items?: OrderItem[];
  actions?: OrderAction[];
  bags?: OrderBag[];
  temperatureClasses?: string[];
  actionStatuses?: string[];
  itemsCount?: OrderItemCount;
  productSkus?: string[];
  localStatus?: OrderLocalStatus;
  checkIn: OrderCheckIn;
  firstName?: string;
  lastName?: string;
}

export interface OrderItemResponse {
  id: string;
  sku: string;
  uuid: string;
  barcode: string;
  quantity: number;
  quantityOriginal: number;
  amount: number | null;
  amountOriginal: number | null;
  unit: string | null;
  status: string; //"picked" | "not_picked";
  isReplaceable: boolean;
  originalId: string | null;
  scannedBarcodes: string[];
}

export interface UseOrdersFilters {
  temperatureClasses?: string[];
  sort?: StorageSortDirection;
  sortBy?: string;
  search?: string;
  searchBy?: string;
  status?: string;
  localStatus?: string[];
  isCheckedIn?: BooleanNumber;
}

export interface UseOrdersSearchFilters extends UseOrdersFilters {
  searchQueries: string[];
}

export interface UseOrders {
  orders: Ref<Order[]>;
  filters: Ref<UseOrdersFilters>;
  loading: Ref<boolean>;
  error: Ref<Error>;
  searchedOrders: Ref<Order[]>;

  loadOrders: (params: UseOrdersFilters) => Promise<void>;
  searchOrders: (params: UseOrdersSearchFilters) => Promise<void>;
}

export interface UseCheckInOrders {
  checkCheckedInOrders: () => Promise<void>;
  notifyAboutCheckIn: () => void;
  isCheckedInOrdersAvailable: Ref<boolean>;
  firstCheckedInOrder: Ref<Order | undefined>;
  checkInBanner: Ref<boolean>;
  showBanner: Ref<boolean>;
}

export interface UseOnlineStatus {
  isOnline: Ref<boolean>;
}

export interface OrderDefaultPatchAttributes {
  timestamp: Date;
  events: EventList[];
}

export interface PickingStarted extends OrderDefaultPatchAttributes {
  status: OrderActionStatus.picking;
}

export interface PickupStarted extends OrderDefaultPatchAttributes {
  status: OrderActionStatus.handover;
}

export interface PickingCompletedItem {
  id: string;
  sku: string;
  quantity: number;
  amount?: number;
  unit?: string;
  originalId?: string;
  scannedBarcodes?: string[];
}

export interface PickingCompletedAgeVerificationFailed {
  failureReason: string | null;
}

export interface PickingCompletedAgeVerificationComplete {
  documentId: string | null;
  dateOfBirth: string | null;
}

export interface PickingCompleted extends OrderDefaultPatchAttributes {
  status: OrderActionStatus.picking_completed;
  items: PickingCompletedItem[];
  events: EventList[];
}

export interface PickingCancelled extends OrderDefaultPatchAttributes {
  status: OrderActionStatus.cancellation_started;
  events: EventList[];
}

export interface PickupCancelled extends OrderDefaultPatchAttributes {
  status: OrderActionStatus.cancellation_started;
  events: EventList[];
}

export interface PickupCompleted extends OrderDefaultPatchAttributes {
  status: OrderActionStatus.handover_completed;
  items: PickingCompletedItem[];
  age:
    | PickingCompletedAgeVerificationFailed
    | PickingCompletedAgeVerificationComplete
    | null;
  events: EventList[];
}

export class PickingStartedOptions implements PickingStarted {
  status = OrderActionStatus.picking as const;

  constructor(public timestamp: Date, public events: EventList[]) {}
}

export class PickupStartedOptions implements PickupStarted {
  status = OrderActionStatus.handover as const;

  constructor(public timestamp: Date, public events: EventList[]) {}
}

export class PickingCompletedOptions implements PickingCompleted {
  status = OrderActionStatus.picking_completed as const;

  constructor(
    public timestamp: Date,
    public items: PickingCompletedItem[],
    public events: EventList[],
    public deliveryUnits: DeliveryUnit[],
  ) {}
}

export class PickingCancelledOptions implements PickingCancelled {
  status = OrderActionStatus.cancellation_started as const;

  constructor(public timestamp: Date, public events: EventList[]) {}
}

export class PickupCancelledOptions implements PickupCancelled {
  status = OrderActionStatus.cancellation_started as const;

  constructor(public timestamp: Date, public events: EventList[]) {}
}

export class PickupCompletedOptions implements PickupCompleted {
  status = OrderActionStatus.handover_completed as const;

  constructor(
    public timestamp: Date,
    public items: PickingCompletedItem[],
    public age:
      | PickingCompletedAgeVerificationFailed
      | PickingCompletedAgeVerificationComplete
      | null,
    public events: EventList[],
  ) {}
}

export type OrderPatchAttributes =
  | PickingStarted
  | PickingCompleted
  | PickupStarted
  | PickupCompleted
  | PickingCancelled
  | PickupCancelled;

export class OrderPickingPatch {
  type = 'picking-orders';

  constructor(public attributes: OrderPatchAttributes) {}
}

export interface TourDropBox {
  label: string;
  code: string;
  number: string;
  compartment: string;
  completeBox: boolean;
  bags: TourDropBoxBag[];
  temperatureClass: TemperatureClassList[];
}

export interface TourDropBoxBag {
  temperatureClass: TemperatureClassList;
  number: string;
  quantity: number;
  label: string;
  code: string;
}

export interface OrderStatusChangeData {
  orderId: string;
  status: OrderLocalStatus;
}

export interface BottleDeposit {
  key: string;
  quantity: number;
}

export interface ExchangedItem {
  reference: string;
  quantity: number;
}

export interface OrderCheckin {
  id: string;
  checkin: boolean;
}

export interface EventList {
  name: string;
  timestamp: string;
  isPatchedToBackEnd?: boolean;
}

export type Tab = {
  linkTo: string;
  label: string;
  status: OrderActionStatus;
  localStatus: OrderLocalStatus;
  hasReminder?: boolean;
};

export const PickingTab: Tab = {
  linkTo: '#picking',
  label: $t('components.tabs.picking.text'),
  status: OrderActionStatus.picking,
  localStatus: OrderLocalStatus.PickingReady,
};

export const HandoverTab: Tab = {
  linkTo: '#handover',
  label: $t('components.tabs.handover.text'),
  status: OrderActionStatus.handover,
  localStatus: OrderLocalStatus.HandoverReady,
};

export const tabs = {
  Picking: PickingTab,
  Handover: HandoverTab,
} as const;

export const tabsList = Object.values(tabs);

export interface OrderAdapterService<T> {
  createOrders(from: T[]): Promise<Order[]>;
}

export type OrderPluginDto = {
  order: Order;
  targetRoute?: RouteLocationNamedRaw;
  isTokenRefreshFailed?: boolean;
};

export type OrderParsePluginDto = {
  orderRaw: OrderResponse | OrderRaw;
  order: Order;
  products?: Product[];
  existingOrder?: Order;
  rawItems: OrderItemRaw[] | OrderItemResponse[];
};

export type ConfigTracking = {
  [key: string]: {
    name: OrderEventNames;
    options: OptionsEvents;
  };
};

export type ConfigLogging = {
  [key: string]: {
    message: string;
  };
};

export const OrderEventBusEventsMap = {
  'picking-started': new PickingStartedEvent(),
  'picking-continued': new PickingContinuedEvent(),
  'picking-completed': new PickingCompletedEvent(),
  'handover-started': new HandoverStartedEvent(),
  'handover-completed': new HandoverCompletedEvent(),
} as const;
